// export default [
//   {
//     path: "/",
//     redirect: "/login",
//   },
//   {
//     path: "*",
//     component: () => import("./NotFound.vue"),
//     name: "NotFound",
//     meta: { requiredAuth: false },
//   },
//   {
//     path: "/login",
//     component: () => import("./Login.vue"),
//     name: "Login",
//     meta: { requiredAuth: false },
//   },
//   {
//     path: "/logout",
//     component: () => import("./Logout.vue"),
//     name: "Logout",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/sign-up",
//     component: () => import("./SignUp.vue"),
//     name: "SignUp",
//     meta: { requiredAuth: false },
//   },
//   {
//     path: "/verify-email/:token",
//     component: () => import("./EmailVerification.vue"),
//     name: "EmailVerification",
//     meta: { requiredAuth: false },
//   },
//   {
//     path: "/basic-info",
//     component: () => import("./BasicInfo.vue"),
//     name: "BasicInfo",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/dashboard",
//     component: () => import("./Dashboard.vue"),
//     name: "Dashboard",
//     meta: { requiredAuth: true },
//   },
//   // {
//   //   path: '/configs/recruitments/add',
//   //   component: ()=>import('./Hr/Recruitments/Form.vue'),
//   //   name: 'Recruitments-add',
//   //   meta: {requiredAuth: true}
//   // },
//   {
//     path: "/hr/yearly-holidays",
//     component: () => import("./Hr/YearlyHolidays/HolidayList.vue"),
//     name: "HolidayList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/add-holiday",
//     component: () => import("./Hr/YearlyHolidays/AddHoliday.vue"),
//     name: "AddHoliday",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/add-from-holiday/:hash_ids?",
//     component: () => import("./Hr/YearlyHolidays/AddFromHoliday.vue"),
//     name: "AddFromHoliday",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/update-holiday/:id?",
//     component: () => import("./Hr/YearlyHolidays/EditHoliday.vue"),
//     name: "UpdateHoliday",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/recruitments/jobs",
//     component: () => import("./Hr/Recruitments/JobList.vue"),
//     name: "JobList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/recruitments/add-or-update-job/:id?",
//     // component: ()=>import('./Hr/Recruitments/Form.vue'),
//     component: () => import("./Hr/Recruitments/AddOrUpdateJob.vue"),
//     name: "AddOrUpdateJob",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/recruitments/job-vacancy-details/:id?",
//     // component: ()=>import('./Hr/Recruitments/Form.vue'),
//     component: () => import("./Hr/Recruitments/Details_v2.vue"),
//     name: "JobVacancyDetails",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/job-applicants/:job_id?",
//     component: () => import("./Hr/JobApplicants/List.vue"),
//     name: "JobApplicants",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/job-applicants/details/:job_id/:employee_id",
//     component: () => import("./Hr/JobApplicants/DetailPages/Details.vue"),
//     //name: 'JobApplicantDetails',
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/job-applicants-details/:hash_ids",
//     component: () =>
//       import("./Hr/JobApplicants/DetailPages/JobApplicantDetails.vue"),
//     name: "JobApplicantDetails",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/add-or-update-applicant/:job_id/:applicant_id?",
//     component: () => import("./Hr/JobApplicants/AddOrUpdateApplicant.vue"),
//     name: "AddOrUpdateApplicant",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/add-applicant/:id?",
//     component: () => import("./Hr/JobApplicants/AddOrUpdateApplicant.vue"),
//     name: "MainTab",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/edit-applicant/:id?",
//     component: () => import("./Hr/JobApplicants/AddOrUpdateApplicant.vue"),
//     name: "EditApplicant",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/job-weight-interviewer-list/:id?",
//     component: () =>
//       import("./Hr/JobApplicants/Interview/JobWeightInterviewerList.vue"),
//     name: "WeightInterviewerList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/ladders/list",
//     component: () => import("./Ladder/List.vue"),
//     name: "LadderList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/ladder/task-board/:id?",
//     component: () => import("./Ladder/TaskBoard.vue"),
//     name: "TaskBoard",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/tasks/list/:id?",
//     component: () => import("./Task/List.vue"),
//     name: "TaskList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/own-tasks",
//     component: () => import("./Task/OwnTasks.vue"),
//     name: "OwnTasks",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/notes",
//     component: () => import("./Notes.vue"),
//     name: "Notes",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/requisitions",
//     component: () => import("./Requisitions/List.vue"),
//     name: "Requisitions",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/404",
//     component: () => import("../404.vue"),
//     name: "404NotFound",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/settings/company/profile",
//     component: () => import("./Settings/Company/CompanyProfile.vue"),
//     name: "CompanyProfile",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/settings/company/services",
//     component: () => import("./Settings/Company/Service/List.vue"),
//     name: "ServiceList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/settings/office",
//     component: () => import("./Settings/Office/OfficeList.vue"),
//     name: "OfficeList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/settings/office/add-office/:id?",
//     component: () => import("./Settings/Office/AddOffice.vue"),
//     name: "AddOffice",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/settings/departments",
//     component: () => import("./Settings/Department/List.vue"),
//     name: "DepartmentList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/settings/roles-and-permissions",
//     component: () => import("./Settings/Role/Roles.vue"),
//     name: "RolesSettings",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/settings/add-role/:id?",
//     component: () => import("./Settings/Role/AddRole.vue"),
//     name: "SettingsAddRole",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/settings/payroll-head",
//     component: () => import("./Settings/Payroll/PayrollHead.vue"),
//     name: "List",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/settings/grade-and-structure",
//     component: () => import("./Settings/Payroll/GradeAndStructure.vue"),
//     name: "List",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/settings/employee/payroll",
//     component: () => import("./Settings/Payroll/List.vue"),
//     name: "List",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/settings/payment-receipt",
//     component: () => import("./Settings/salary/Salary.vue"),
//     name: "Salary",
//     meta: { requiredAuth: true },
//   },

//   {
//     path: "/settings/employee-salary-list/:month/:year",
//     component: () => import("./Settings/salary/SalaryList.vue"),
//     name: "SalaryList",
//     meta: { requiredAuth: true },
//   },

//   {
//     // path: '/hr/recruitments/interview-panel/:id?',
//     path: "/hr/recruitments/interview-panel/:applicantId?",
//     component: () => import("./Hr/JobApplicants/Interview/InterviewPanel.vue"),
//     name: "InterviewPanel",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/emp/list",
//     component: () => import("./Hr/Employees/EmployeeList.vue"),
//     name: "Employees",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/employees/details/:employee_id?",
//     // component: ()=>import('./Hr/Employees/Details.vue'),
//     component: () => import("./Hr/Employees/DetailPages/Details.vue"),
//     name: "EmployeeDetails",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/employees/add-or-update/:employee_id?",
//     component: () =>
//       import("./Hr/Employees/AddUpdateEmployee/AddOrUpdatePage.vue"),
//     name: "AddOrUpdateEmployee",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/emp/attendance",
//     component: () => import("./Hr/Attendance/AttendanceList.vue"),
//     name: "Attendance",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/attendance/import",
//     component: () => import("./Hr/Attendance/ImportEmployeeAttendance.vue"),
//     name: "AttendanceImport",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/emp/advance-attendance-search",
//     component: () => import("./Hr/Attendance/AdvanceAttendanceSearch.vue"),
//     name: "AdvanceAttendanceSearch",
//     meta: { requiredAuth: true },
//   },

//   {
//     path: "/hr/emp/leave-applications",
//     component: () => import("./Hr/Employees/LeaveApplications.vue"),
//     name: "LeaveApplications",
//     meta: { requiredAuth: true },
//   },

//   {
//     path: "/emp/leave-applications",
//     component: () => import("./Hr/Employees/EmpLeaveApplications.vue"),
//     name: "EmpLeaveApplications",
//     meta: { requiredAuth: true },
//   },

//   {
//     path: "/hr/emp/leave-report",
//     component: () => import("./Settings/EarlyLeaveReason/Report.vue"),
//     name: "Leave-report",
//     meta: { requiredAuth: true },
//   },

//   {
//     path: "/emp/leave-report",
//     component: () => import("./Settings/EarlyLeaveReason/EmpReport.vue"),
//     name: "empLeave-report",
//     meta: { requiredAuth: true },
//   },

//   {
//     path: "/hr/emp/leave-report/:id?",
//     component: () => import("./Settings/EarlyLeaveReason/ReportSingle.vue"),
//     name: "Leave-report-Single",
//     meta: { requiredAuth: true },
//   },

//   {
//     path: "/hr/emp/leave-applications/add-edit/:id?",
//     component: () => import("./Hr/Employees/LeaveApplicationAddEdit.vue"),
//     name: "LeaveApplicationAddEdit",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/hr/emp/leave-applications/:id",
//     component: () => import("./Hr/Employees/LeaveApplicationDetails.vue"),
//     name: "LeaveApplicationDetails",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/settings/user-list",
//     component: () => import("./Settings/User/UserList.vue"),
//     name: "UserList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/reset-password/:password_reset_token",
//     component: () => import("./ResetPassword.vue"),
//     name: "ResetPassword",
//     meta: { requiredAuth: false },
//   },
//   {
//     path: "/forgot-password",
//     component: () => import("./ForgotPassword"),
//     name: "ForgotPassword",
//     meta: { requiredAuth: false },
//   },
//   {
//     path: "/settings/designations",
//     component: () => import("./Settings/Designation/List.vue"),
//     name: "DesignationList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/user/account",
//     component: () => import("./User/Account.vue"),
//     name: "UserAccount",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/crm/lead/list",
//     component: () => import("./CRM/Lead/List.vue"),
//     name: "LeadList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/crm/lead/import",
//     component: () => import("./CRM/Lead/ImportLeads.vue"),
//     name: "ImportLeads",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/crm/lead/details/:id",
//     component: () => import("./CRM/Lead/Details.vue"),
//     name: "LeadDetails",
//     meta: { requiredAuth: true },
//   },
//   // {
//   //   path: '/crm/lead-product/list',
//   //   component: ()=>import('./CRM/LeadProduct/List.vue'),
//   //   name: 'LeadProductList',
//   //   meta: {requiredAuth: true}
//   // },
//   {
//     path: "/inventory/settings/brand",
//     component: () => import("./inventory/settings/brand/List.vue"),
//     name: "inventorySettingsBrandList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/inventory/settings/category",
//     component: () => import("./inventory/settings/category/List.vue"),
//     name: "inventorySettingsCategoryList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/inventory/settings/unit",
//     component: () => import("./inventory/settings/unit/List.vue"),
//     name: "inventorySettingsUnitList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/inventory/settings/manufacture",
//     component: () => import("./inventory/settings/manufacture/List.vue"),
//     name: "inventorySettingsManufactureList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/inventory/settings/origin",
//     component: () => import("./inventory/settings/origin/List.vue"),
//     name: "inventorySettingsOriginList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/inventory/product",
//     component: () => import("./inventory/product/List.vue"),
//     name: "inventoryProductList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/inventory/quotation",
//     component: () => import("./inventory/quotation/List.vue"),
//     name: "inventoryQuotationtList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/inventory/add-quotation",
//     component: () => import("./inventory/quotation/add.vue"),
//     name: "inventoryAddQuotationt",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/inventory/invoice",
//     component: () => import("./inventory/invoice/List.vue"),
//     name: "inventoryInvoiceList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/inventory/create-invoice",
//     component: () => import("./inventory/invoice/create.vue"),
//     name: "inventoryCreateList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/inventory/preview-invoice",
//     component: () => import("./inventory/invoice/preview.vue"),
//     name: "inventoryPreviewInvoice",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/settings/permissions",
//     component: () => import("./Settings/Role/Permissions.vue"),
//     name: "Permissions",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/settings/early-leave-reason",
//     component: () => import("./Settings/EarlyLeaveReason/List.vue"),
//     name: "EarlyLeaveReasonList",
//     meta: { requiredAuth: true },
//   },

//   {
//     path: "/settings/leave-setup",
//     component: () => import("./Settings/EarlyLeaveReason/Setup.vue"),
//     name: "LeaveSetup",
//     meta: { requiredAuth: true },
//   },

//   {
//     path: "/crm/survey/list",
//     component: () => import("./CRM/Survey/SuveyList.vue"),
//     name: "SurveyList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/lead-survey/:ref_number",
//     component: () => import("./SurveyForm"),
//     name: "SurveyForm",
//     meta: { requiredAuth: false },
//   },
//   {
//     path: "/lead-survey-completed/:ref_number",
//     component: () => import("./SurveyCompleted"),
//     name: "SurveyCompleted",
//     meta: { requiredAuth: false },
//   },
//   {
//     path: "/accounts/invoice/list",
//     component: () => import("./Accounts/InvoiceList.vue"),
//     name: "AccountsInvoiceList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/accounts/invoice/add/:upd_invoice_id?/:duplicate?",
//     component: () => import("./Accounts/CreateInvoice.vue"),
//     name: "CreateInvoice",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/accounts/invoice/details/:upd_invoice_id?/:is_details?",
//     component: () => import("./Accounts/InvoiceDetails.vue"),
//     name: "InvoiceDetails",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/accounts/bank/list",
//     component: () => import("./Accounts/BankList.vue"),
//     name: "AccountsBankList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/accounts/employee-bank-list",
//     component: () => import("./Accounts/EmployeeBankList.vue"),
//     name: "EmployeeBankList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/accounts/company-currency",
//     component: () => import("./Accounts/CompanyCurrency.vue"),
//     name: "CompanyCurrency",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/crm/newsletter/list",
//     component: () => import("./CRM/NewsLetter/List.vue"),
//     name: "NewsLetterList",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/crm/newsletter/add/:id?",
//     component: () => import("./CRM/NewsLetter/AddNewsLetter.vue"),
//     name: "AddNewsLetter",
//     meta: { requiredAuth: true },
//   },
//   {
//     path: "/applyjob/:job_id",
//     component: () => import("./ApplyJob.vue"),
//     name: "ApplyJob",
//     meta: { requiredAuth: false },
//   },
//   {
//     path: "/auth/google/callback",
//     component: () => import("./GoogleAuthorize.vue"),
//     name: "Authorize with Google Calendar",
//     meta: { requiredAuth: false },
//   },
// ];
export default [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "*",
    component: () => import("./NotFound.vue"),
    name: "NotFound",
    meta: {
      requiredAuth: false,
    },
  },
  {
    path: "/not-found",
    component: () => import("./NotFound.vue"),
    name: "404",
    meta: {
      requiredAuth: false,
    },
  },
  {
    path: "/login",
    component: () => import("./Login.vue"),
    name: "Login",
    meta: {
      requiredAuth: false,
    },
  },
  {
    path: "/logout",
    component: () => import("./Logout.vue"),
    name: "Logout",
    meta: {
      requiredAuth: true,
      permission: "",
    },
  },
  {
    path: "/sign-up",
    component: () => import("./SignUp.vue"),
    name: "SignUp",
    meta: {
      requiredAuth: false,
      permission: " ",
    },
  },
  {
    path: "/verify-email/:token",
    component: () => import("./EmailVerification.vue"),
    name: "EmailVerification",
    meta: { requiredAuth: false },
  },
  {
    path: "/basic-info",
    component: () => import("./BasicInfo.vue"),
    name: "BasicInfo",
    meta: { requiredAuth: true },
  },
  {
    path: "/dashboard",
    component: () => import("./Dashboard.vue"),
    name: "Dashboard",
    meta: {
      requiredAuth: true,
      permission: "show_dashboard",
    },
  },
  // {
  //   path: '/configs/recruitments/add',
  //   component: ()=>import('./Hr/Recruitments/Form.vue'),
  //   name: 'Recruitments-add',
  //   meta: {requiredAuth: true}
  // },
  {
    path: "/hr/yearly-holidays",
    component: () => import("./Hr/YearlyHolidays/HolidayList.vue"),
    name: "HolidayList",
    meta: {
      requiredAuth: true,
      permission: "show_yearly_holidays",
    },
  },
  {
    path: "/hr/add-holiday",
    component: () => import("./Hr/YearlyHolidays/AddHoliday.vue"),
    name: "AddHoliday",
    meta: { requiredAuth: true },
  },
  {
    path: "/hr/add-from-holiday/:hash_ids?",
    component: () => import("./Hr/YearlyHolidays/AddFromHoliday.vue"),
    name: "AddFromHoliday",
    meta: { requiredAuth: true },
  },
  {
    path: "/hr/update-holiday/:id?",
    component: () => import("./Hr/YearlyHolidays/EditHoliday.vue"),
    name: "UpdateHoliday",
    meta: { requiredAuth: true },
  },
  {
    path: "/hr/recruitments/jobs",
    component: () => import("./Hr/Recruitments/JobList.vue"),
    name: "JobList",
    meta: {
      requiredAuth: true,
      permission: "show_job_vacancy",
    },
  },
  {
    path: "/hr/recruitments/add-or-update-job/:id?",
    // component: ()=>import('./Hr/Recruitments/Form.vue'),
    component: () => import("./Hr/Recruitments/AddOrUpdateJob.vue"),
    name: "AddOrUpdateJob",
    meta: { requiredAuth: true },
  },
  {
    path: "/hr/recruitments/job-vacancy-details/:id?",
    // component: ()=>import('./Hr/Recruitments/Form.vue'),
    component: () => import("./Hr/Recruitments/Details_v2.vue"),
    name: "JobVacancyDetails",
    meta: { requiredAuth: true },
  },
  {
    path: "/hr/job-applicants/:job_id?",
    component: () => import("./Hr/JobApplicants/List.vue"),
    name: "JobApplicants",
    meta: { requiredAuth: true },
  },
  {
    path: "/hr/job-applicants/details/:job_id/:employee_id",
    component: () => import("./Hr/JobApplicants/DetailPages/Details.vue"),
    //name: 'JobApplicantDetails',
    meta: { requiredAuth: true },
  },
  {
    path: "/hr/job-applicants-details/:hash_ids",
    component: () =>
      import("./Hr/JobApplicants/DetailPages/JobApplicantDetails.vue"),
    name: "JobApplicantDetails",
    meta: { requiredAuth: true },
  },
  {
    path: "/hr/add-or-update-applicant/:job_id/:applicant_id?",
    component: () => import("./Hr/JobApplicants/AddOrUpdateApplicant.vue"),
    name: "AddOrUpdateApplicant",
    meta: { requiredAuth: true },
  },
  {
    path: "/hr/add-applicant/:id?",
    component: () => import("./Hr/JobApplicants/AddOrUpdateApplicant.vue"),
    name: "MainTab",
    meta: { requiredAuth: true },
  },
  {
    path: "/hr/edit-applicant/:id?",
    component: () => import("./Hr/JobApplicants/AddOrUpdateApplicant.vue"),
    name: "EditApplicant",
    meta: { requiredAuth: true },
  },
  {
    path: "/hr/job-weight-interviewer-list/:id?",
    component: () =>
      import("./Hr/JobApplicants/Interview/JobWeightInterviewerList.vue"),
    name: "WeightInterviewerList",
    meta: { requiredAuth: true },
  },
  {
    path: "/ladders/list",
    component: () => import("./Ladder/List.vue"),
    name: "LadderList",
    meta: {
      requiredAuth: true,
      permission: "show_task_ladder",
    },
  },
  {
    path: "/ladder/task-board/:id?",
    component: () => import("./Ladder/TaskBoard.vue"),
    name: "TaskBoard",
    meta: { requiredAuth: true },
  },
  {
    path: "/tasks/list/:id?",
    component: () => import("./Task/List.vue"),
    name: "TaskList",
    meta: { requiredAuth: true },
  },
  {
    path: "/own-tasks",
    component: () => import("./Task/OwnTasks.vue"),
    name: "OwnTasks",
    meta: {
      requiredAuth: true,
      permission: "manage_tasks",
    },
  },
  {
    path: "/notes",
    component: () => import("./Notes.vue"),
    name: "Notes",
    meta: {
      requiredAuth: true,
      permission: "manage_notes",
    },
  },
  {
    path: "/requisitions",
    component: () => import("./Requisitions/List.vue"),
    name: "Requisitions",
    meta: {
      requiredAuth: true,
      permission: "manage_requisitions",
    },
  },
  // {
  //   path: "/404",
  //   component: () => import("./NotFound.vue"),
  //   name: "404NotFound",
  //   meta: {
  //     requiredAuth: true,
  //     permission:'add_other_user'
  //   },
  // },
  {
    path: "/settings/company/profile",
    component: () => import("./Settings/Company/CompanyProfile.vue"),
    name: "CompanyProfile",
    meta: {
      requiredAuth: true,
      permission: "manage_company_profile",
    },
  },
  {
    path: "/settings/company/services",
    component: () => import("./Settings/Company/Service/List.vue"),
    name: "ServiceList",
    meta: {
      requiredAuth: true,
      permission: "manage_company_services",
    },
  },
  {
    path: "/settings/office",
    component: () => import("./Settings/Office/OfficeList.vue"),
    name: "OfficeList",
    meta: {
      requiredAuth: true,
      permission: "manage_company_office",
    },
  },
  {
    path: "/settings/office/add-office/:id?",
    component: () => import("./Settings/Office/AddOffice.vue"),
    name: "AddOffice",
    meta: { requiredAuth: true },
  },
  {
    path: "/settings/departments",
    component: () => import("./Settings/Department/List.vue"),
    name: "DepartmentList",
    meta: {
      requiredAuth: true,
      permission: "manage_company_departments",
    },
  },
  {
    path: "/settings/roles-and-permissions",
    component: () => import("./Settings/Role/Roles.vue"),
    name: "RolesSettings",
    meta: {
      requiredAuth: true,
      permission: "manage_role_permission",
    },
  },
  {
    path: "/settings/add-role/:id?",
    component: () => import("./Settings/Role/AddRole.vue"),
    name: "SettingsAddRole",
    meta: {
      requiredAuth: true,
      permission: "manage_role_permission",
    },
  },

  {
    path: "/settings/payroll-head",
    component: () => import("./Settings/Payroll/PayrollHead.vue"),
    name: "List",
    meta: {
      requiredAuth: true,
      permission: "manage_payroll_head",
    },
  },

  {
    path: "/settings/company-head",
    component: () => import("./Settings/Company/Service/CompanyHead.vue"),
    name: "CompanyHead",
    meta: {
      requiredAuth: true,
      permission: "manage_payroll_head",
    },
  },

  {
    path: "/settings/grade-and-structure",
    component: () => import("./Settings/Payroll/GradeAndStructure.vue"),
    name: "List",
    meta: {
      requiredAuth: true,
      permission: "manage_payroll_head",
    },
  },
  {
    path: "/settings/employee/payroll",
    component: () => import("./Settings/Payroll/List.vue"),
    name: "List",
    meta: {
      requiredAuth: true,
      permission: "manage_payroll_head",
    },
  },

  {
    path: "/settings/payment-receipt",
    component: () => import("./Settings/salary/Salary.vue"),
    name: "Salary",
    meta: {
      requiredAuth: true,
      permission: "manage_payroll_head",
    },
  },

  {
    path: "/settings/penalty-bonus/:id/:month/:year",
    component: () => import("./Settings/salary/PenaltyBonus.vue"),
    name: "Salary",
    meta: {
      requiredAuth: true,
      permission: "manage_payroll_head",
    },
  },

  {
    path: "/settings/monthly-attendance-report/:id/:month/:year",
    component: () => import("./Settings/salary/MonthlyAttendanceReport.vue"),
    name: "Salary",
    meta: {
      requiredAuth: true,
      permission: "manage_payroll_head",
    },
  },

  {
    path: "/settings/employee-salary-list/:month/:year",
    component: () => import("./Settings/salary/SalaryList.vue"),
    name: "SalaryList",
    meta: {
      requiredAuth: true,
      permission: "manage_payroll_head",
    },
  },
  {
    // path: '/hr/recruitments/interview-panel/:id?',
    path: "/hr/recruitments/interview-panel/:applicantId?",
    component: () => import("./Hr/JobApplicants/Interview/InterviewPanel.vue"),
    name: "InterviewPanel",
    meta: { requiredAuth: true },
  },
  {
    path: "/hr/emp/list",
    component: () => import("./Hr/Employees/EmployeeList.vue"),
    name: "Employees",
    meta: {
      requiredAuth: true,
      permission: "show_employee_list",
    },
  },
  {
    path: "/hr/employees/details/:employee_id?",
    // component: ()=>import('./Hr/Employees/Details.vue'),
    component: () => import("./Hr/Employees/DetailPages/Details.vue"),
    name: "EmployeeDetails",
    meta: {
      requiredAuth: true,
      permission: "show_employee",
    },
  },
  {
    path: "/hr/employees/add-or-update/:employee_id?",
    component: () =>
      import("./Hr/Employees/AddUpdateEmployee/AddOrUpdatePage.vue"),
    name: "AddOrUpdateEmployee",
    meta: { requiredAuth: true },
  },
  {
    path: "/hr/emp/attendance",
    component: () => import("./Hr/Attendance/AttendanceList.vue"),
    name: "Attendance",
    meta: {
      requiredAuth: true,
      permission: "show_attendance_list",
    },
  },
  {
    path: "/hr/attendance/import",
    component: () => import("./Hr/Attendance/ImportEmployeeAttendance.vue"),
    name: "AttendanceImport",
    meta: { requiredAuth: true },
  },
  {
    path: "/hr/emp/advance-attendance-search",
    component: () => import("./Hr/Attendance/AdvanceAttendanceSearchV2.vue"),
    name: "AdvanceAttendanceSearch",
    meta: {
      requiredAuth: true,
      permission: "show_advance_attendance_search",
    },
  },
  {
    path: "/hr/emp/attendance-correction-request",
    component: () => import("./Hr/Attendance/AttendanceCorrectionRequest.vue"),
    name: "AttendanceCorrectionRequest",
    meta: {
      requiredAuth: true,
      permission: "show_attendance_list",
    }
  },
  {
    path: "/hr/emp/assets-allocation",
    component: () => import("./Hr/Employees/AssetsAllocation.vue"),
    name: "EmployeesAssetsAllocation",
    meta: {
      requiredAuth: true,
      permission: "employees_assets_allocation",
    }
  },
  {
    path: "/hr/emp/leave-applications",
    component: () => import("./Hr/Employees/LeaveApplications.vue"),
    name: "LeaveApplications",
    meta: {
      requiredAuth: true,
      permission: "show_emp_leave_applications",
    },
  },

  {
    path: "/emp/leave-applications",
    component: () => import("./Hr/Employees/EmpLeaveApplications.vue"),
    name: "EmpLeaveApplications",
    meta: {
      requiredAuth: true,
      permission: "leave_applications",
    },
  },

  {
    path: "/hr/emp/leave-report",
    component: () => import("./Settings/EarlyLeaveReason/Report.vue"),
    name: "Leave-report",
    meta: {
      requiredAuth: true,
      permission: "show_emp_leave_reports",
    },
  },

  {
    path: "/emp/leave-report",
    component: () => import("./Settings/EarlyLeaveReason/EmpReport.vue"),
    name: "empLeave-report",
    meta: {
      requiredAuth: true,
      permission: "leave_reports",
    },
  },

  {
    path: "/hr/emp/leave-report/:id?",
    component: () => import("./Settings/EarlyLeaveReason/ReportSingle.vue"),
    name: "Leave-report-Single",
    meta: { requiredAuth: true },
  },

  {
    path: "/hr/emp/leave-applications/add-edit/:id?",
    component: () => import("./Hr/Employees/LeaveApplicationAddEdit.vue"),
    name: "LeaveApplicationAddEdit",
    meta: { requiredAuth: true },
  },
  {
    path: "/hr/emp/leave-applications/:id",
    component: () => import("./Hr/Employees/LeaveApplicationDetails.vue"),
    name: "LeaveApplicationDetails",
    meta: { requiredAuth: true },
  },
  {
    path: "/settings/user-list",
    component: () => import("./Settings/User/UserList.vue"),
    name: "UserList",
    meta: { requiredAuth: true },
  },
  {
    path: "/reset-password/:password_reset_token",
    component: () => import("./ResetPassword.vue"),
    name: "ResetPassword",
    meta: { requiredAuth: false },
  },
  {
    path: "/forgot-password",
    component: () => import("./ForgotPassword"),
    name: "ForgotPassword",
    meta: { requiredAuth: false },
  },
  {
    path: "/settings/designations",
    component: () => import("./Settings/Designation/List.vue"),
    name: "DesignationList",
    meta: {
      requiredAuth: true,
      permission: "manage_designations",
    },
  },
  {
    path: "/user/account",
    component: () => import("./User/Account.vue"),
    name: "UserAccount",
    meta: { requiredAuth: true },
  },
  {
    path: "/crm/lead/list",
    component: () => import("./CRM/Lead/List.vue"),
    name: "LeadList",
    meta: {
      requiredAuth: true,
      permission: "crm_lead_list",
    },
  },
  {
    path: "/crm/lead/import",
    component: () => import("./CRM/Lead/ImportLeads.vue"),
    name: "ImportLeads",
    meta: { requiredAuth: true },
  },
  {
    path: "/crm/lead/details/:id",
    component: () => import("./CRM/Lead/Details.vue"),
    name: "LeadDetails",
    meta: { requiredAuth: true },
  },
  // {
  //   path: '/crm/lead-product/list',
  //   component: ()=>import('./CRM/LeadProduct/List.vue'),
  //   name: 'LeadProductList',
  //   meta: {requiredAuth: true}
  // },
  {
    path: "/inventory/settings/brand",
    component: () => import("./inventory/settings/brand/List.vue"),
    name: "inventorySettingsBrandList",
    meta: { requiredAuth: true },
  },
  {
    path: "/inventory/settings/category",
    component: () => import("./inventory/settings/category/List.vue"),
    name: "inventorySettingsCategoryList",
    meta: { requiredAuth: true },
  },
  {
    path: "/inventory/settings/unit",
    component: () => import("./inventory/settings/unit/List.vue"),
    name: "inventorySettingsUnitList",
    meta: { requiredAuth: true },
  },
  {
    path: "/inventory/settings/manufacture",
    component: () => import("./inventory/settings/manufacture/List.vue"),
    name: "inventorySettingsManufactureList",
    meta: { requiredAuth: true },
  },
  {
    path: "/inventory/settings/origin",
    component: () => import("./inventory/settings/origin/List.vue"),
    name: "inventorySettingsOriginList",
    meta: { requiredAuth: true },
  },
  {
    path: "/inventory/product",
    component: () => import("./inventory/product/List.vue"),
    name: "inventoryProductList",
    meta: { requiredAuth: true },
  },
  {
    path: "/inventory/quotation",
    component: () => import("./inventory/quotation/List.vue"),
    name: "inventoryQuotationtList",
    meta: { requiredAuth: true },
  },
  {
    path: "/inventory/add-quotation",
    component: () => import("./inventory/quotation/add.vue"),
    name: "inventoryAddQuotationt",
    meta: { requiredAuth: true },
  },
  {
    path: "/inventory/invoice",
    component: () => import("./inventory/invoice/List.vue"),
    name: "inventoryInvoiceList",
    meta: { requiredAuth: true },
  },
  {
    path: "/inventory/create-invoice",
    component: () => import("./inventory/invoice/create.vue"),
    name: "inventoryCreateList",
    meta: { requiredAuth: true },
  },
  {
    path: "/inventory/preview-invoice",
    component: () => import("./inventory/invoice/preview.vue"),
    name: "inventoryPreviewInvoice",
    meta: { requiredAuth: true },
  },
  {
    path: "/settings/permissions",
    component: () => import("./Settings/Role/Permissions.vue"),
    name: "Permissions",
    meta: { requiredAuth: true },
  },
  {
    path: "/settings/early-leave-reason",
    component: () => import("./Settings/EarlyLeaveReason/List.vue"),
    name: "EarlyLeaveReasonList",
    meta: { requiredAuth: true },
  },

  {
    path: "/settings/leave-setup",
    component: () => import("./Settings/EarlyLeaveReason/Setup.vue"),
    name: "LeaveSetup",
    meta: {
      requiredAuth: true,
      permission: "manage_leave_setup",
    },
  },

  {
    path: "/crm/survey/list",
    component: () => import("./CRM/Survey/SuveyList.vue"),
    name: "SurveyList",
    meta: {
      requiredAuth: true,
      permission: "crm_survey_list",
    },
  },
  {
    path: "/crm/survey/view/:id",
    component: () => import("./CRM/Survey/LeadView.vue"),
    name: "LeadView",
    meta: { requiredAuth: true,},
  },

  {
    path: "/lead-survey/:ref_number",
    component: () => import("./SurveyForm"),
    name: "SurveyForm",
    meta: { requiredAuth: false },
  },
  {
    path: "/lead-survey-completed/:ref_number",
    component: () => import("./SurveyCompletedV2"),
    name: "SurveyCompleted",
    meta: { requiredAuth: false },
  },
  {
    path: "/accounts/invoice/list",
    component: () => import("./Accounts/InvoiceList.vue"),
    name: "AccountsInvoiceList",
    meta: {
      requiredAuth: true,
      permission: "account_invoice_list",
    },
  },
  {
    path: "/accounts/invoice/add/:upd_invoice_id?/:duplicate?",
    component: () => import("./Accounts/CreateInvoice.vue"),
    name: "CreateInvoice",
    meta: { requiredAuth: true },
  },
  {
    path: "/accounts/invoice/details/:upd_invoice_id?/:is_details?",
    component: () => import("./Accounts/InvoiceDetails.vue"),
    name: "InvoiceDetails",
    meta: { requiredAuth: true },
  },
  {
    path: "/accounts/bank/list",
    component: () => import("./Accounts/BankList.vue"),
    name: "AccountsBankList",
    meta: {
      requiredAuth: true,
      permission: "bank_list",
    },
  },

  {
    path: "/accounts/transaction/list",
    component: () => import("./Accounts/TransactionList.vue"),
    name: "TransactionList",
    meta: {
      requiredAuth: true,
      permission: "manage_crm",
    },
  }, {
    path: "/accounts/transaction/report",
    component: () => import("./Accounts/TransactionReport.vue"),
    name: "TransactionReport",
    meta: {
      requiredAuth: true,
      permission: "manage_crm",
    }
  },
  {
    path: "/accounts/employee-bank-list",
    component: () => import("./Accounts/EmployeeBankList.vue"),
    name: "EmployeeBankList",
    meta: {
      requiredAuth: true,
      permission: "emp_bank_list",
    },
  },
  {
    path: "/accounts/company-currency",
    component: () => import("./Accounts/CompanyCurrency.vue"),
    name: "CompanyCurrency",
    meta: {
      requiredAuth: true,
      permission: "company_currency",
    },
  },
  {
    path: "/crm/newsletter/list",
    component: () => import("./CRM/NewsLetter/List.vue"),
    name: "NewsLetterList",
    meta: {
      requiredAuth: true,
      permission: "show_crm_newsletter",
    },
  },
  {
    path: "/crm/newsletter/add/:id?",
    component: () => import("./CRM/NewsLetter/AddNewsLetter.vue"),
    name: "AddNewsLetter",
    meta: { requiredAuth: true },
  },
  {
    path: "/applyjob/:job_id",
    component: () => import("./ApplyJob.vue"),
    name: "ApplyJob",
    meta: { requiredAuth: false },
  },
  {
    path: "/auth/google/callback",
    component: () => import("./GoogleAuthorize.vue"),
    name: "googleCallback",
    meta: { requiredAuth: false },
  },
  {
    path: "/login-otp/:session_id",
    component:()=>import("./Auth/LoginOtp.vue"),
    name: "LoginOtp",
    meta: {requiredAuth: false}
  }
];
